import React from 'react';
import './styles/simple-modal.css'

function SimpleModal({modalName, name, title, children, onCancel, onSuccess}) {
  return (
      <div className="modal fade" id={name} tabIndex="-1" aria-labelledby="simpleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bolder" id="simpleModalLabel">{title}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {children ? children : '...'}
            </div>
            <div className="modal-footer">
              {onCancel?
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
              :null}
              <button type="button" data-bs-dismiss="modal" className="btn btn-primary" onClick={() => onSuccess()}>Guardar cambios</button>
            </div>
          </div>
        </div>
      </div>
  )
}

function SimpleModalButton({modalName, children, color, type}){
  let modal = modalName?modalName:'undefined'
  if (modal !== 'undefined')
    return <div className={!color ? null : 'btn btn-primary'} type={!type ? null : 'button'} data-bs-toggle="modal" data-bs-target={`#${modalName}`}>{children}</div>
  else
    return
}

export {SimpleModal, SimpleModalButton};