import React, { useState } from "react";
import './styles/customizeSchool.css'
// ..Componentes
import { SimpleModal, SimpleModalButton } from '../../../components/modal/simple-modal'


function CustomizeSchool(props) {

  const [options, set_options] = useState([
    { name: 'Mi colegio', selected: true },
    { name: 'Libroteca', selected: true },
    { name: 'Recursos', selected: true },
    { name: 'Saber', selected: true },
    { name: 'Enciclopedia', selected: false },
    { name: 'Mis Textos', selected: true }
  ])

  return (
    <React.Fragment>
      <SimpleModalButton modalName="customizeSchool">
        <button className="btn-animated">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
          </svg>
          <span>Personalizar</span>
        </button>
      </SimpleModalButton>

      <SimpleModal
        title="Personalizar institución"
        name="customizeSchool"
        onSuccess={() => { }}
      >
        {/* <ul className="list-tools">
          {options.map((tool, index) => {
            return <li key={index}>
              <div class="form-check form-switch">
                <input class="form-check-input" disabled type="checkbox" />
                <label class="form-check-label" >{tool.name}</label>
              </div>
            </li>
          })}
        </ul> */}
      </SimpleModal>

    </React.Fragment>
  )

  function handleClick(e) {
    e.preventDefault();
  }
}

export default CustomizeSchool;