// 1. Endpoint del api
// 2. Diccionario de cursos: Si recibe un número o abreviación dice a cuál es el curso.
//
//

// const API_URL = 'http://flask-env.eba-kgzpmw2j.us-east-2.elasticbeanstalk.com/api'
const API_URL = "https://api.educar.com.co/api";
const API_PRODUCCION = "https://api.educar.com.co/api";
//const API_URL = 'http://localhost:5000/api'
const S3_BUCKET =
  "https://s3.us-east-2.amazonaws.com/plataforma.media.files/public/";
const course_dictionary = {
  P: "Párvulos",
  PJ: "Prejardín",
  J: "Jardín",
  T: "Transición",
  1: "Primero",
  2: "Segundo",
  3: "Tercero",
  4: "Cuarto",
  5: "Quinto",
  6: "Sexto",
  7: "Séptimo",
  8: "Octavo",
  9: "Noveno",
  10: "Décimo",
  11: "Undécimo",
  null: "No asignado",
  13: "Treceavo",
};
const permisos_lectura = {
  1: "Estudiante",
  2: "Docente",
  3: "Estudiante y docente",
};

function setDateCustomFormat(input) {
  var date = new Date(input);
  return date.toLocaleDateString();
}

const GRADOS = [
  { label: "Jardín", value: "J" },
  { label: "Párvulos", value: "P" },
  { label: "Prejardín", value: "PJ" },
  { label: "Transición", value: "T" },
  { label: "1°", value: "1" },
  { label: "2°", value: "2" },
  { label: "3°", value: "3" },
  { label: "4°", value: "4" },
  { label: "5°", value: "5" },
  { label: "6°", value: "6" },
  { label: "7°", value: "7" },
  { label: "8°", value: "8" },
  { label: "9°", value: "9" },
  { label: "10°", value: "10" },
  { label: "11°", value: "11" },
  { label: "12°", value: "12" },
  { label: "13", value: "13" },
];

export {
  API_URL,
  API_PRODUCCION,
  S3_BUCKET,
  course_dictionary,
  setDateCustomFormat,
  GRADOS,
  permisos_lectura,
};
