import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './components/helpers/privateRoute'
import CustomizeSchool from './pages/schoolProfile/modals/customizeSchool';
import { SnackbarProvider } from 'notistack';
import Login from './pages/login/login';


// .. Componentes
const SchoolsList = lazy(() => import('./pages/schoolsList'));
const SchoolPage = lazy(() => import('./pages/schoolProfile/schoolProfile'));
const ListaPines = lazy(() => import('./pages/pin'));
const Textos = lazy(() => import('./pages/textos'));
const SetPassword = lazy(() => import('./pages/set-password/set-password'));

const MyRouter = () => (
    <SnackbarProvider maxSnack={5}>
        <Router>
            <Suspense fallback={<div style={{ background: "#4fa3c0", position: 'absolute', width: '100%', minHeight: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><h2 style={{ color: 'white' }}>cargando</h2></div>}>
                <Switch>
                    {/*   RUTAS SIN INCIAR SESIÓN*/}
                    <Route exact path={'/'} >
                        <Redirect to="/ingresar" />
                    </Route>
            <Route path={'/ingresar'} component={Login} />
                    {/*
        *   RUTAS PROTEGIDAS
        *   @desc listar aquí todas las rutas protegidas, es decir, las que
        *   para acceder sea necesario haber iniciado sesión
        */}
                    {/* <PrivateRoute exact path="/" component={Home} /> */}
                    <PrivateRoute exact path="/instituciones" component={SchoolsList} />
                    <PrivateRoute path={"/set-password"} component={SetPassword} />
                    <PrivateRoute path={"/instituciones/pagina/:page"} component={SchoolsList} />
                       {/* <PrivateRoute exact path={["/instituciones/pagina/:page?"]} component={SchoolPage}/> */}
                       <PrivateRoute exact path={["/instituciones/:id"]} component={SchoolPage} />
                    {/* <PrivateRoute exact path={["/instituciones/:id/:typeuser?"]} component={SchoolPage}/> */}
                    <PrivateRoute path={'/pines'} component={ListaPines} />
                    <PrivateRoute path={'/textos'} component={Textos} />
                    <PrivateRoute path={'/borrar'} component={CustomizeSchool} />


                </Switch>
            </Suspense>
        </Router>
    </SnackbarProvider>
);

export default MyRouter;