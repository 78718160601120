import React from "react";
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route 
    
        {...rest}
        render={props=>
            localStorage.getItem('usuario')? (
                <Component {...props} />
            ):(
                <Redirect 
                    to={{
                        pathname: "/ingresar",
                        state:{from: props.location}
                    }}
                />
            )
        }

    />
);

export default PrivateRoute;